<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 商品列表 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="商品名称:">
                    <el-input v-model="queryParams.phone" placeholder="请输入商品名称" clearable></el-input>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增商品</el-button>
            </div>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="序号" prop="id" align="center" width="120"></el-table-column>
                <el-table-column label="商品名称" prop="username" align="center"></el-table-column>

                <el-table-column label="商品图片" prop="avatar" align="center" width="120">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" fit="cover" :class="`column${scope.$index}`" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="品牌" prop="username" align="center"></el-table-column>
                <el-table-column label="分销价" prop="phone" align="center"></el-table-column>
                <el-table-column label="零售价" prop="ctime" align="center"></el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text">置顶</el-button>
                        <el-button type="text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 选择商品对话框 -->
        <Addproduct :addVisible="addVisible" :title="title" :form="form" :goodsList="[]" @toggleAdd="toggleAdd" @getAddGood="getAddGood"></Addproduct>
    </div>
</template>
<script>
import Addproduct from '@/components/Addproduct';
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: 'Users',
    components: {
        Breadcrumb,
        Pagination,
        Addproduct
    },
    data() {
        return {
            defaultImg: require('../../assets/img/timg.jpg'),
            loading: false,
            queryParams: {
                phone: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            form: {
                platform: 'admin'
            },
            pageInfo: {},
            addVisible: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            header: {},
            radio: '1',
            imageUrl: '',
            title: '',
            menuList: ['直播', '直播商品'],
            value: '',
            restaurants: [],
            input: '',
            text: '',
        }
    },
    methods: {
        // 添加商品
        getAddGood(val) {
            // let ids = [];
            // val.map((v) => { ids.push(v.id); })
            // let goods_ids = ids.join(",");
            // request.post('/goods/special/goods/add', { special_id: this.queryParams.special_id, goods_ids: goods_ids }).then(ret => {
            //     if (ret.code == 1) {
            //         this.$message.success('操作成功');
            //         this.loadData();
            //     }
            // });
        },
        //新增商品
        add() {
            this.addVisible = true;

        },
        clear() {
            this.clearVisible = true;
        },
        toggleAdd(val) {
            this.addVisible = val
        },


        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        //直播商品列表
        loadData() {
            // this.loading = true;
            request.get('', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(9999, this.pageInfo)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // //新增用户
        // add() {
        //     this.mode = 'add';
        //     this.title = '新增用户';
        //     this.form = {
        //         state: 2,
        //         platform: 'admin'
        //     };
        //     console.log(this.form)
        //     this.addVisible = true;
        // },
        // //编辑用户
        // edit(index, row) {
        //     this.mode = 'edit';
        //     this.title = '编辑用户';
        //     const item = this.pageInfo.list[index];
        //     this.form = Object.assign({}, item);
        //     this.$set(this.form, 'password', '');
        //     this.$set(this.form, 'id', row.id);
        //     this.addVisible = true;
        // },
        // //新增，编辑用户
        // saveData() {
        //     if (this.$refs.form) {
        //         this.$refs.form.validate(valid => {
        //             if (valid) {
        //                 var url = this.mode == 'add' ? '/user/add' : '/user/edit';
        //                 //处理时间
        //                 console.log(6666, this.form)
        //                 request.post(url, this.form).then(ret => {
        //                     console.log(this.form)
        //                     if (ret.code == 1) {
        //                         this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
        //                             '成功');
        //                         this.addVisible = false;
        //                         this.loadData();
        //                     } else {
        //                         this.addVisible = false;
        //                         this.$message.error(ret.msg);
        //                     }
        //                 });
        //             } else {
        //                 this.$message.error('请补全信息');
        //                 return false;
        //             }
        //         });
        //     }
        // },
        //图片上传
        uploadImage(e) {
            const file = e.target.files[0];
            if (!file.type.includes('image/')) {
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                request.post('/admin/common/up', {
                    img: event.target.result
                }).then((ret) => {
                    if (ret.code == 0) {
                        this.form.avatar = ret.data.uri
                    } else {
                        this.$message.error(ret.msg);

                    }
                });
            };
            reader.readAsDataURL(file);
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    }
}
</script>
<style scoped>
.errImg {
    height: 50px;
    width: 80px !important;
    margin-top: 10px;
    border-radius: 0 !important;
}

.el-table__body-wrapper .is-scrolling-left {
    height: 500px !important;
}

.display {
    display: flex;
    margin-top: 10px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.del-dialog-cnt {
    font-size: 16px;
    text-align: center;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.green {
    color: green;
}

.mr10 {
    margin-right: 10px;
}

.crop-demo-btn {
    position: absolute;
    right: 0px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    float: right;
}

.crop-input {
    position: absolute;
    width: 100px;
    height: 40px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.pre-img {
    width: 100px;
    height: 100px;
    background: #f8f8f8;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-top: 10px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    /* margin-left: 5px; */
    margin-top: 10px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    font-size: 28px;
    line-height: 100px;
}

.avatar-uploader .avatar-uploader-icon {
    line-height: 100px;
}

.cell {
    text-align: center;
}

.el-input--suffix .el-input__inner {
    padding-right: 15px;
}
</style>